import React from "react";
import { Input, ElementForm } from "@mjcom/form-component";
import { ModelManager } from "@mjcom/model-manager";
import { history } from "../../History";

export default class CompanyForm extends ElementForm {
  constructor(props) {
    let element = {
      email: "",
      repeatEmail: "",
      plainPassword: "",
      repeatPassword: "",
      firstname: "",
      lastname: "",
      company: "",
      vat: "",
    };

    const requiredFields = [
      "company",
      "email",
      "repeatEmail",
      "plainPassword",
      "repeatPassword",
      "firstname",
      "lastname",
    ];

    super(props, element, requiredFields);
    this.state.companyId = null;
    this.state.isLoading = false;
    this.state.isError = false;

    this.mapAndCleanErrors = this.mapAndCleanErrors.bind(this);
  }

  submit(e) {
    e.preventDefault();
    const violations = this.state.requiredFields
      .map((requiredField) => ({
        propertyPath: requiredField,
        message: "Ce champ est obligatoire.",
      }))
      .filter(
        (requiredField) =>
          this.state.element[requiredField.propertyPath] === "" ||
          this.state.element[requiredField.propertyPath] === null ||
          this.state.element[requiredField.propertyPath] === 0
      );

    if (this.state.element.email !== this.state.element.repeatEmail) {
      violations.push({
        propertyPath: "email",
        message: "Les emails ne correspondent pas.",
      });
    }

    if (
      this.state.element.plainPassword !== this.state.element.repeatPassword
    ) {
      violations.push({
        propertyPath: "plainPassword",
        message: "Les mots de passe ne correspondent pas.",
      });
    }

    if (violations.length === 0) {
      let company = {
        "@id": "/api/companies",
        name: this.state.element.company,
        vat: this.state.element.vat,
      };

      let user = {
        "@id": "/api/users/owner",
        email: this.state.element.email,
        repeatEmail: this.state.element.repeatEmail,
        plainPassword: this.state.element.plainPassword,
        repeatPassword: this.state.element.repeatPassword,
        firstname: this.state.element.firstname,
        lastname: this.state.element.lastname,
      };

      this.setState({ isLoading: true });
      this.setState({ isError: false });

      if (!this.state.companyId) {
        ModelManager.save(company, true)
          .finally(() => this.setState({ isLoading: false }))
          .then((element) => {
            const companyId = `/api/companies/${element.id}`;
            this.setState({ companyId });
            this.setState({ isLoading: true });
            user.company = companyId;
            ModelManager.save(user, true)
              .finally(() => this.setState({ isLoading: false }))
              .then(() => {
                history.push("/congratulation");
              })
              .catch((error) => {
                this.mapAndCleanErrors(error);
              });
          })
          .catch((error) => {
            this.mapAndCleanErrors(error);
          });
      } else {
        user.company = this.state.companyId;
        this.setState({ isLoading: true });
        ModelManager.save(user, true)
          .finally(() => this.setState({ isLoading: false }))
          .then(() => {
            history.push("/congratulation");
          })
          .catch((error) => {
            this.mapAndCleanErrors(error);
          });
      }
    } else {
      this.mapAndCleanErrors({ violations });
    }
  }

  mapAndCleanErrors(error = {}) {
    this.setState({ isError: true });
    window.location.href = "#main";

    if (error.violations) {
      this.mapErrors(error.violations);
    } else {
      this.cleanErrors();
    }
  }

  render() {
    return (
      <>
        {this.state.isError && (
          <div className="alert alert-danger" role="alert">
            Une erreur est survenue
          </div>
        )}
        {this.state.isLoading ? (
          <div className="loading">
            <img src="/loading.gif" alt="loading" />
          </div>
        ) : (
          <div>
            <form className="ajax-contact-form form-horizontal" noValidate>
              <h5 className="clear">Ma société</h5>
              <div className="form-row col-md-12 form-input-item">
                <div className="col-md-5">
                  <Input
                    name="company"
                    disabled={this.state.companyId}
                    label="Société"
                    type="text"
                    value={this.state.element.company}
                    errors={this.state.errors.company}
                    updateElement={this.updateElement}
                    required={this.isRequired("company")}
                  />
                </div>
                <div className="col-md-5">
                  <Input
                    name="vat"
                    disabled={this.state.companyId}
                    label="Numéro tva intracommunautaire"
                    type="text"
                    value={this.state.element.vat}
                    errors={this.state.errors.vat}
                    updateElement={this.updateElement}
                    required={this.isRequired("vat")}
                  />
                </div>
              </div>

              <h5 className="clear">Identifiants</h5>
              <div className="form-row col-md-12 form-input-item">
                <div className="col-md-5">
                  <Input
                    name="email"
                    label="Email"
                    type="email"
                    value={this.state.element.email}
                    errors={this.state.errors.email}
                    updateElement={this.updateElement}
                    required={this.isRequired("email")}
                  />
                </div>

                <div className="col-md-5">
                  <Input
                    name="repeatEmail"
                    label="Confirmez l'email"
                    type="email"
                    value={this.state.element.repeatEmail}
                    errors={this.state.errors.repeatEmail}
                    updateElement={this.updateElement}
                    required={this.isRequired("repeatEmail")}
                  />
                </div>
              </div>

              <div className="form-row col-md-12 form-input-item">
                <div className="col-md-5">
                  <Input
                    name="plainPassword"
                    label="Mot de passe"
                    type="password"
                    value={this.state.element.plainPassword}
                    errors={this.state.errors.plainPassword}
                    updateElement={this.updateElement}
                    required={this.isRequired("plainPassword")}
                    disabled={false}
                  />
                </div>

                <div className="col-md-5">
                  <Input
                    name="repeatPassword"
                    label="Confirmez le mot de passe"
                    type="password"
                    value={this.state.element.repeatPassword}
                    errors={this.state.errors.repeatPassword}
                    updateElement={this.updateElement}
                    required={this.isRequired("repeatPassword")}
                    disabled={false}
                  />
                </div>
              </div>

              <h5 className="clear">Mes coordonnées</h5>

              <div className="form-row col-md-12 form-input-item">
                <div className="col-md-5">
                  <Input
                    name="firstname"
                    label="Prénom"
                    type="text"
                    value={this.state.element.firstname}
                    errors={this.state.errors.firstname}
                    updateElement={this.updateElement}
                    required={this.isRequired("firstname")}
                  />
                </div>

                <div className="col-md-5">
                  <Input
                    name="lastname"
                    label="Nom"
                    type="text"
                    errors={this.state.errors.lastname}
                    value={this.state.element.lastname}
                    updateElement={this.updateElement}
                    required={this.isRequired("lastname")}
                  />
                </div>
              </div>

              <div className="col-md-10">
                <button
                  type="submit"
                  className="btn btn-black btn-rounded pull-right"
                  onClick={this.submit}
                >
                  Soumettre
                </button>
              </div>
            </form>

            <div className="col-md-12">
              <small className="form-text text-muted">(*) Champs requis.</small>
            </div>
          </div>
        )}
      </>
    );
  }
}
