import React from "react";
import Scroll from "react-scroll";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1>L'outil innovant de plan de charge</h1>
          <p>
            Pilotez efficacement <b>vos ressources (humaines / matérielles)</b>{" "}
            ainsi que <b>le temps passé</b> à l'aide du{" "}
            <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par
            plusieurs entreprises et chef de projet.
          </p>
          <p>
            Découvrez Workload, l'outil innovant de gestion de plan de charge.
          </p>
        </div>
      </div>

      <Scroll.Element name="main"></Scroll.Element>

      <section className="mt-120 mt-md-80 mt-sm-60 mb-120 mb-md-80 mb-sm-54">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title-wrap">
                <h2 className="mb-8">Politique de confidentialité</h2>
              </div>
              <div id="privacy-polycy" className="content area-title mb-44">
                <p>
                  Les traitements de données à caractère personnel visés par la
                  présente politique de confidentialité sont soumis au droit
                  français, et notamment à la Loi Informatique et Libertés du 6
                  janvier 1978 dans ses versions modifiées, et au Règlement UE
                  2016/679 du 27 avril 2016 relatif à la protection des
                  personnes physiques à l'égard du traitement des données à
                  caractère personnel et à la libre circulation de ces données
                  (RGPD), ci-après dénommée «&nbsp;la Règlementation données
                  personnelles&nbsp;».
                </p>

                <h3>1. Responsable du traitement</h3>

                <p>
                  L’Editeur, dont le siège social est situé 799 chemin des 4
                  moulins, 83500 La Seyne-sur-Mer.
                </p>

                <p>Numéro RCS : 845 134 956 R.C.S. TOULON</p>

                <p>Ci-après dénommée «&nbsp;Editeur&nbsp;»</p>

                <h3>
                  2. A qui s’adresse notre politique de protection des données ?
                </h3>

                <p>
                  Cette politique s’applique à tous les visiteurs du site web{" "}
                  <a href="https://workload.fr">https://workload.fr</a>{" "}
                  (ci-après «&nbsp;notre site web&nbsp;»), nos membres inscrits,
                  ainsi qu’aux personnes qui nous demandent des informations.
                </p>

                <p>
                  Le terme «&nbsp;données à caractère personnel&nbsp;» (ci-après
                  «&nbsp;Données&nbsp;») a le sens défini dans la Règlementation
                  données personnelles.
                </p>

                <p>
                  Afin que vous puissiez naviguer sur le site{" "}
                  <a href="https://workload.fr">https://workload.fr</a> en toute
                  confiance, nous nous engageons à traiter vos Données
                  conformément à la Règlementation données personnelles. Cette
                  politique de confidentialité vise à décrire en toute
                  transparence la manière et la raison dont sont traitées vos
                  Données. Le terme «&nbsp;traitement de Données&nbsp;» est ici
                  utilisé pour désigner la collecte et le traitement des
                  Données.
                </p>

                <h3>3. Catégories de Données traitées</h3>

                <p>
                  Nous sommes susceptibles de traiter les Données suivantes :
                </p>

                <p>
                  <strong>Les Données que vous renseignez&nbsp;</strong>
                </p>

                <p>Remplissage d’un formulaire&nbsp;:</p>

                <ul>
                  <li>Nom, prénom</li>
                  <li>Adresse email</li>
                  <li>Code postal</li>
                  <li>Ville</li>
                  <li>Date d’enregistrement des informations</li>
                </ul>

                <p>
                  <strong>
                    Les Données que nous collectons automatiquement lorsque vous
                    utilisez nos services&nbsp;
                  </strong>
                </p>

                <p>
                  Afin de vous permettre d’accéder aux services proposés par
                  notre site web, nous collectons des Données d’utilisation afin
                  de, par exemple, mesurer l’audience de notre site web ou le
                  nombre de pages vues. Nous utilisons les identifiants de
                  connexion de vos appareils mobiles, votre adresse IP et des
                  cookies pour vous identifier et pour enregistrer votre
                  activité.
                </p>

                <p>
                  Nous pouvons également être amenés à collecter des données
                  techniques sur votre connexion internet, votre navigateur et
                  vos appareils (exemple&nbsp;: iPhone ou Android, PC ou Mac,
                  taille de police, etc.). Pour plus d’informations sur
                  l’utilisation des cookies, consultez le point « cookies »
                  ci-dessous.
                </p>

                <h3>4. Utilisations de vos Données et bases légales</h3>

                <p>
                  <strong>
                    Traitement de vos Données sur la base du consentement de la
                    personne concernée (Article 6 a. du RGPD)&nbsp;{" "}
                  </strong>
                </p>

                <p>
                  Nous traitons vos Données, dès lors que vous y consentez, pour
                  les finalités suivantes&nbsp;:
                </p>

                <ul>
                  <li>Vous envoyez nos newsletters&nbsp;;</li>
                  <li>
                    Vous communiquer des informations sur nos produits et
                    services par e-mail ;
                  </li>
                  <li>
                    Communiquer vos données à nos partenaires commerciaux afin
                    qu’ils vous contactent à des fins de prospection marketing
                    pour leurs produits et services, éventuellement sur base de
                    votre profil et moyennant votre autorisation spécifique
                    préalablement à ce transfert&nbsp;;
                  </li>
                  <li>
                    Personnaliser nos publicités en fonction de votre
                    environnement proche&nbsp;;
                  </li>
                </ul>

                <p>
                  Vous pouvez retirer votre consentement à tout moment dans les
                  conditions décrites à l’article 8 ci-dessous.
                </p>

                <p>
                  <strong>
                    Utilisation de vos Données sur la base du respect de nos
                    obligations légales (Article 6 c. du RGPD)
                  </strong>
                </p>

                <p>
                  Afin de satisfaire aux obligations légales auxquelles nous
                  sommes, dans certains cas, soumis à des obligations légales
                  imposant le traitement et/ou la transmission de certaines
                  données personnelles aux administrations ou institutions
                  déterminées par la loi (exemple&nbsp;: police, administration
                  fiscale, etc.).
                </p>

                <p>
                  <strong>
                    Utilisation de vos Données sur la base de notre intérêt
                    légitime&nbsp;(Article 6 f. du RGPD)
                  </strong>
                </p>

                <p>
                  Lorsque nous avons un intérêt légitime, soit dans le cadre de
                  nos activités marketing, soit dans le cadre de la réalisation
                  concrète de nos activités économiques,&nbsp;nous collectons et
                  traitons des Données en vue des finalités suivantes&nbsp;:
                </p>

                <ul>
                  <li>
                    Afin de faire des études, tests et analyses (y compris
                    études de clients ou de marché) en vue de l’amélioration de
                    nos solutions, nos procédures internes et/ou notre service
                    global,
                  </li>
                  <li>
                    Afin de faire des analyses statistiques ou de tendance pour
                    des fins de gestion financière, de marketing ou de
                    reporting,
                  </li>
                  <li>
                    Afin de prévenir et de combattre les éventuelles fraudes et
                    abus.
                  </li>
                </ul>

                <h3>5. Transmission de vos données à des tiers&nbsp;</h3>

                <p>
                  &nbsp;Vos données peuvent être communiquées à des tiers dans
                  les cas suivants&nbsp;:
                </p>

                <ul>
                  <li>
                    A certains de nos sous-traitants dans le cadre de
                    prestations pour notre compte (exemple&nbsp;: agence
                    marketing, prestataire d’hébergement, etc.) et uniquement
                    sur base de nos instructions.
                  </li>
                  <li>
                    À certains de nos partenaires commerciaux sous réserve
                    d’avoir préalablement obtenu votre consentement
                  </li>
                </ul>

                <p>
                  <em>Transfert de données en dehors de l'Union Européenne</em>
                </p>

                <p>
                  Aucune donnée n'est transférée en dehors de l'Union
                  Européenne.
                </p>

                <h3>6. Durée de conservation de vos données ?</h3>

                <p>
                  Vos Données sont conservées pour une durée strictement
                  nécessaire aux finalités poursuivies. La période de
                  conservation peut varier selon la finalité des traitements.
                </p>

                <table border="1" cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <strong>Catégories de données personnelles </strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>Durées de conservation en base active</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Données clients, Données liées à des demandes
                          spécifiques
                        </p>
                      </td>
                      <td>
                        <p>
                          1 an à compter de la fin de l’opération ou, à défaut,
                          1 an après le dernier contact du consommateur, outre
                          les contraintes légales d’archivage.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Données des opt-in newsletters et / ou des opt-in
                          partenaires (sous réserve de votre consentement
                          exprès)
                        </p>
                      </td>
                      <td>
                        <p>
                          Conservation des données tant que la personne
                          concernée ne se désinscrit pas (via le lien de
                          désinscription prévu) et tant qu’elle demeure cliente.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Cookies</p>
                      </td>
                      <td>
                        <p>13 mois maximum.</p>

                        <p>Cf. Politique cookies.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Données susceptibles de faire l’objet d’une
                          réquisition judiciaire (données de connexion,
                          identité, coordonnées de contact, données relatives
                          aux transactions)
                        </p>
                      </td>
                      <td>
                        <p>12 mois à compter de leur collecte</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p>
                  Vos Données peuvent également être conservées plus longtemps
                  pour d’autres finalités, par exemple pour se conformer à nos
                  obligations légales (ex. obligations comptables et fiscales).
                  L’accès aux Données archivées est limité.
                </p>

                <h3>7. Sécurité de vos données&nbsp;</h3>

                <p>
                  L’Editeur accorde une importance particulière à la sécurité
                  des données à caractère personnel.
                </p>

                <p>
                  L’Editeur a mis en place des mesures utiles, qu’elles soient
                  physiques, logiques, administratives ou organisationnelles,
                  adaptées au degré de sensibilité des Données, en vue d’assurer
                  l’intégrité et la confidentialité des Données et de les
                  protéger contre toute intrusion malveillante, perte,
                  altération ou divulgation à des tiers non autorisés.
                </p>

                <p>
                  Lorsqu’il a recours à un prestataire, L’Editeur ne lui
                  communique des Données qu’après avoir vérifié le respect de
                  ces principes de sécurité.
                </p>

                <p>
                  L’Editeur peut être conduit à effectuer des audits afin de
                  vérifier la bonne application opérationnelle des règles
                  relatives à la sécurité des Données.
                </p>

                <h3>8. Vos droits</h3>

                <p>
                  <strong>Exercice de vos droits</strong>
                </p>

                <p>
                  Vous devez nous <strong>adresser</strong> une demande écrite,
                  accompagnée d’une <strong>preuve de</strong>{" "}
                  <strong>votre identité</strong> (carte d’identité, passeport,
                  permis de conduire), afin d’éviter que nous ne divulguions vos
                  Données à d’autres personnes.
                </p>

                <p>
                  Vous pouvez nous envoyer votre demande à l’adresse
                  suivante&nbsp;:
                </p>

                <p>
                  Mathieu JUERS - 799 chemin des 4 moulins - 83500 La
                  Seyne-sur-Mer
                </p>

                <p>
                  Si vous nous demandez ces informations sous format
                  électronique, sauf contre-ordre de votre part, nous vous
                  répondrons également par voie électronique.
                </p>

                <p>
                  <em>
                    Nous attirons votre attention sur le fait que votre demande
                    est liée à l’adresse e-mail que vous nous communiquez pour
                    vous identifier. Si vous avez créé plusieurs comptes en
                    enregistrant plusieurs adresses e-mail différentes, vous
                    devrez nous communiquer toutes les adresses e-mail
                    concernées par votre demande.
                  </em>
                </p>

                <p>
                  Conformément à la Règlementation données personnelles, votre
                  demande sera traitée dans les trente jours, à compter du
                  moment où nous sommes en possession de tous les éléments
                  nécessaires au traitement de celle-ci. Si votre demande est
                  complexe, ce délai peut être prolongé de deux mois maximum,
                  mais nous vous en informerons préalablement.
                </p>

                <p>
                  <strong>
                    <em>Votre droit d’accès et de rectification&nbsp; </em>
                  </strong>
                </p>

                <p>
                  Conformément à la règlementation GDPR, vous disposez d’un
                  droit d’obtenir la confirmation que vos Données sont traitées
                  ou non et d’un droit d’y accéder le cas échéant et de nous en
                  demander une copie.
                </p>

                <p>
                  Vous avez également le droit de nous demander de compléter
                  et/ou de rectifier les informations vous concernant.
                </p>

                <p>
                  <strong>
                    <em>
                      Votre droit d’opposition et d’effacement de vos Données
                    </em>
                  </strong>
                </p>

                <p>
                  Il s’agit de votre droit de vous opposer à tout moment et sans
                  justification au traitement de vos Données par l’Editeur.
                  Toutefois l’Editeur pourra refuser d’accéder à votre requête
                  en cas de motifs légitimes et impérieux.
                </p>

                <p>
                  Vous pouvez obtenir de l’Editeur que vos Données soient
                  effacées de nos bases, dans les modalités définies par la
                  Règlementation données personnelles, sous réserve de nos
                  obligations légales de conservation.
                </p>

                <p>
                  <strong>
                    <em>Votre droit à la limitation du traitement</em>
                  </strong>
                </p>

                <p>
                  En cas de contestation relative au traitement de vos Données,
                  vous pouvez demander à ce que le traitement de vos Données
                  soit suspendu le temps de l’instruction de la contestation.
                  Dans ce cas, nous n’utiliserons plus vos Données tant que la
                  contestation n’aura pas été résolue.
                </p>

                <p>
                  Vous pouvez exercer ce droit lors que vous vous opposez au
                  traitement, que vous contestez l’exactitude de vos Données,
                  que vous pensez que leur traitement est illicite ou lorsque
                  vous en avez besoin pour la constatation, l’exercice ou la
                  défense de vos droits en justice.
                </p>

                <p>
                  <strong>
                    <em>Votre droit à la portabilité de vos données</em>
                  </strong>
                </p>

                <p>
                  Vous pouvez nous demander de transférer les Données que vous
                  nous avez communiquées à un autre responsable de traitement
                  dans un format structuré, couramment utilisé et lisible, dans
                  les limites prévues par la Règlementation données
                  personnelles. Dans ce cas, nous les transférerons au
                  destinataire de votre choix.
                </p>

                <p>
                  <strong>
                    Votre droit de ne pas faire l’objet d’une décision fondée
                    sur un traitement automatisé
                  </strong>
                </p>

                <p>
                  Dans le cadre de nos activités, nous sommes susceptibles de
                  mettre en place des traitements automatisés de vos Données.
                  Vous avez le droit de ne pas faire l’objet d’une décision
                  fondée exclusivement sur un traitement automatisé produisant
                  des effets juridiques vous concernant ou vous affectant, sauf
                  lorsque cette décision est nécessaire à la conclusion ou à
                  l’exécution d’un contrat, ou est autorisée légalement.
                </p>

                <p>
                  <strong>
                    Votre droit d’introduire une action auprès de la CNIL
                  </strong>
                </p>

                <p>
                  Vous pouvez porter votre réclamation auprès de l’autorité de
                  contrôle française, la CNIL (Commission Nationale de
                  l'Informatique et des Libertés) en introduisant une action.
                </p>

                <p>
                  <strong>
                    Votre droit de donner des directives en cas de décès
                  </strong>
                </p>

                <p>
                  Vous avez le droit de donner des directives sur le sort de vos
                  Données après votre décès.
                </p>

                <h3>9. Délégué à la Protection des Données</h3>

                <p>
                  Pour plus d’informations sur notre politique de protection des
                  Données ou pour toute réclamation concernant votre vie privée,
                  vous pouvez contacter notre Délégué à la Protection des
                  Données Mathieu JUERS par courrier à l’adresse suivante&nbsp;:
                  799 chemin des 4 moulins - 83500 La Seyne-sur-Mer.
                </p>

                <h3>10. Politique Cookies</h3>

                <p>
                  Le présent site et ses tiers partenaires fournissent divers
                  services sur ses sites Internet et ses applications mobiles en
                  utilisant des cookies. Nous plaçons des cookies sur votre
                  terminal pour vous fournir un accès enrichi et personnalisé à
                  des services essentiels et vous fournir d'autres services dans
                  le but de rendre votre visite plus pertinente.
                </p>

                <h3>11. Qu’est-ce qu’un cookie ?</h3>

                <p>
                  Les cookies sont de petits fichiers texte placés sur le disque
                  dur de votre terminal (ordinateur, tablette ou téléphone
                  mobile), par un éditeur de site Internet ou d’une application
                  mobile grâce à votre logiciel de navigation. Le cookie
                  attribue un identifiant unique à votre terminal. Cependant il
                  ne stocke pas vos Données et ne vous reconnait donc pas en
                  tant qu’individu. Il ne reconnait que le terminal que vous
                  utilisez.
                </p>

                <p>
                  Le cookie aide à naviguer de page en page sur un site
                  Internet, à adapter le contenu de nos services, à fournir des
                  connexions sécurisées et se souvenir de vos préférences de
                  navigation.
                </p>

                <h3>12. Les cookies utilisés sur notre site internet</h3>

                <p>
                  Les services fournis grâce à l’utilisation de cookies
                  incluent&nbsp;: la connexion utilisateur, la gestion de vos
                  paniers, la protection et la sécurité. Ils sont essentiels au
                  bon fonctionnement du site.
                </p>

                <table border="1" cellPadding="0">
                  <thead>
                    <tr>
                      <th>
                        <p>
                          <strong>Types de cookies (Finalités)</strong>
                        </p>
                      </th>
                      <th>
                        <p>
                          <strong>Objectifs</strong>
                        </p>
                      </th>
                      <th>
                        <p>
                          <strong>Exemples</strong>
                        </p>
                      </th>
                      <th>
                        <p>
                          <strong>Durée de conservation</strong>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <u>cookies fonctionnels ou de personnalisation</u>
                        </p>
                      </td>
                      <td>
                        <p>
                          Ces cookies participent au bon fonctionnement du site
                          d’un point de vue technique et personnalisent
                          l'expérience de navigation sur le site.
                        </p>

                        <p>
                          Ils permettent de mémoriser les informations afin de
                          naviguer sur le site. Ils ne collectent aucune
                          information personnelle et ne peuvent être utilisés à
                          des fins commerciales.
                        </p>
                      </td>
                      <td>
                        <p>Accès à votre espace personnel.</p>

                        <p>
                          Se souvenir des données de connexion : identifiant /
                          mot de passe / langue / infos de connexion / du
                          dernier produit commandé.
                        </p>

                        <p>
                          Se souvenir des préférences du site : affichage /
                          acceptation ou refus des cookies / langue.
                        </p>

                        <p>Pré-remplir certains champs de nos formulaires.</p>
                      </td>
                      <td>
                        <p>1 mois</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <u>
                            cookies de performance ou analytiques (analyse
                            d'audience)
                          </u>
                        </p>
                      </td>
                      <td>
                        <p>
                          Ils permettent d’établir des statistiques anonymes sur
                          la fréquentation du site pour en améliorer le contenu.
                          Ils ne peuvent pas être utilisés à des fins
                          commerciales.
                        </p>

                        <p>
                          Ils nous aident à mesurer l’efficacité d’une campagne
                          publicitaire.
                        </p>
                      </td>
                      <td>
                        <p>
                          Calcul du nombre de clics, des messages d'erreur,
                          rubriques et contenus visités, parcours de
                          l’internaute.
                        </p>

                        <p>Mesurer le retour sur les campagnes.</p>
                      </td>
                      <td>
                        <p>13 mois</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <u>cookies publicitaires </u>
                        </p>
                      </td>
                      <td>
                        <p>
                          Ils ciblent les publicités qui vont sont diffusées sur
                          le site en fonction de vos centres d'intérêt sur le
                          site et sur des sites tiers.
                        </p>

                        <p>
                          Cela vous permet de bénéficier de contenus
                          publicitaires pertinents.
                        </p>
                      </td>
                      <td>
                        <p>Ciblage, Campagne d'emailing.</p>
                      </td>
                      <td>
                        <p>13 mois</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <u>cookies de nos partenaires</u>
                        </p>
                      </td>
                      <td>
                        <p>
                          Des cookies peuvent être déposés sur votre navigateur,
                          avec votre consentement, par nos partenaires agissant
                          en qualité de responsables de traitements. On parle de
                          cookies tiers.
                        </p>
                      </td>
                      <td>
                        <p>
                          Intégration sur notre site de certains modules et
                          fonctionnalités, comme les boutons rattachés aux
                          réseaux sociaux,
                        </p>

                        <p>Publicité ciblée.</p>
                      </td>
                      <td>
                        <p>
                          L’Editeur ne gère pas les cookies de ces sociétés et
                          n’a aucun contrôle ni responsabilité sur ces cookies
                          et sur l’utilisation des données collectées par leur
                          biais. Nous vous conseillons de consulter la propre
                          politique cookies de ces partenaires.&nbsp;
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h3>13. Vos choix par rapport aux cookies</h3>

                <p>
                  Nous vous invitons à exprimer vos choix en matière de cookies
                  ou à les modifier par les moyens décrits ci-dessous.
                </p>

                <p>
                  <strong>
                    Gérer les cookies via votre navigateur internet{" "}
                  </strong>
                </p>

                <p>
                  La rubrique «&nbsp;Aide&nbsp;» des navigateurs internet vous
                  indique comment modifier vos souhaits et notamment comment
                  refuser les nouveaux cookies, obtenir un message de
                  notification vous signalant leur réception ou les désactiver.
                  Afin de faciliter votre démarche, voici les liens vers les
                  rubriques «&nbsp;Aide&nbsp;» des navigateurs les plus
                  couramment utilisés :
                  <ul>
                    <li>
                      Internet Explorer™ :{" "}
                      <u>
                        <a
                          href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                        </a>
                      </u>
                    </li>
                    <li>
                      Safari™ :{" "}
                      <a
                        href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
                      </a>
                    </li>
                    <li>
                      Chrome™ :{" "}
                      <u>
                        <a
                          href="http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647
                        </a>
                      </u>
                    </li>
                    <li>
                      Firefox™ :{" "}
                      <u>
                        <a
                          href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
                        </a>
                      </u>
                    </li>
                    <li>
                      Opera™ :{" "}
                      <a
                        href="https://www.opera.com/fr/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>https://www.opera.com/fr/privacy</u>
                      </a>
                    </li>
                  </ul>
                  Pour plus d’informations sur la façon dont fonctionnent les
                  cookies et la publicité ciblée, vous pouvez consulter les
                  sites{" "}
                  <u>
                    <a
                      href="http://www.youronlinechoices.eu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.youronlinechoices.eu
                    </a>
                  </u>{" "}
                  et{" "}
                  <u>
                    <a
                      href="http://www.allaboutcookies.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.allaboutcookies.org
                    </a>
                  </u>
                  .&nbsp; Ces réglages ne sont cependant valables que sur le
                  navigateur que vous utilisez actuellement.
                </p>

                <p>
                  <strong>
                    Gérer vos choix concernant les cookies publicitaires
                    utilisés par l’Editeur
                  </strong>
                </p>

                <p>
                  Les cookies fonctionnels et de performance ne peuvent être
                  désactivés sans altérer le bon fonctionnement du site. Il
                  s’agit de cookies techniques qui nous permettent de vous
                  fournir nos services.
                </p>

                <p>
                  Pour gérer les cookies publicitaires, veuillez-vous reporter à
                  la rubrique ci-dessus.
                </p>

                <h3>14. Nos engagements sur les Cookies</h3>

                <p>
                  Nous nous engageons en faveur de la protection de votre vie
                  privée et à respecter les deux principes suivants : nous
                  n'associons à vos cookies aucune information permettant de
                  vous identifier personnellement. Les cookies ne permettent en
                  aucun cas de connaître votre adresse, votre date de naissance,
                  votre numéro de téléphone ou toute autre information
                  permettant de vous identifier. Nous ne fournissons aucune
                  information personnelle aux annonceurs ni aux sites tiers qui
                  affichent nos annonces basées sur vos centres d'intérêt. Si
                  votre terminal est utilisé par plusieurs personnes, ou s'il
                  dispose de plusieurs logiciels de navigation, il est possible
                  que les contenus affichés aux autres utilisateurs
                  correspondent à vos préférences. Si vous le souhaitez, vous
                  pouvez modifier les paramètres de votre navigateur en
                  consultant la section « Gérer les cookies via votre navigateur
                  internet »&nbsp;ci-dessus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
