import React from "react";
import Scroll from "react-scroll";

export default function LegalNotice() {
  return (
    <div>
      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1>L'outil innovant de plan de charge</h1>
          <p>
            Pilotez efficacement <b>vos ressources (humaines / matérielles)</b>{" "}
            ainsi que <b>le temps passé</b> à l'aide du{" "}
            <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par
            plusieurs entreprises et chef de projet.
          </p>
          <p>
            Découvrez Workload, l'outil innovant de gestion de plan de charge.
          </p>
        </div>
      </div>

      <Scroll.Element name="main"></Scroll.Element>

      <section className="mt-120 mt-md-80 mt-sm-60 mb-120 mb-md-80 mb-sm-54">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title-wrap">
                <h2 className="mb-8">Mentions légales ?</h2>
              </div>
              <div id="legal-notice" className="area-title content mb-44">
                <p>
                  La société Mathieu JUERS, soucieuse des droits des individus,
                  notamment au regard des traitements automatisés et dans une
                  volonté de transparence avec ses clients, a mis en place une
                  politique reprenant l’ensemble de ces traitements, des
                  finalités poursuivies par ces derniers ainsi que des moyens
                  d’actions à la disposition des individus afin qu’ils puissent
                  au mieux exercer leurs droits.
                </p>
                <p>
                  Pour toute information complémentaire sur la protection des
                  données personnelles, nous vous invitons à consulter le site :{" "}
                  <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
                </p>

                <p>
                  La poursuite de la navigation sur ce site vaut acceptation
                  sans réserve des dispositions et conditions d'utilisation qui
                  suivent.
                </p>
                <p>
                  La version actuellement en ligne de ces conditions
                  d'utilisation est la seule opposable pendant toute la durée
                  d'utilisation du site et jusqu'à ce qu'une nouvelle version la
                  remplace.
                </p>

                <h3>Article 1 - Mentions légales</h3>

                <h4>1.1 Site (ci-après « le site ») :</h4>

                <p>Workload</p>

                <h4>1.2 Éditeur (ci-après « l'éditeur ») : </h4>

                <p>
                  Mathieu JUERS SASU au capital de 50 € dont le siège social est
                  situé : 799 chemin des 4 moulins 83500 représentée par Mathieu
                  JUERS, en sa qualité de Président immatriculée au RCS de
                  Toulon sous le numéro 845 134 956, adresse mail :{" "}
                  <a href="mailto:contact@workload.fr">contact@workload.fr</a>,
                  téléphone +33 9 72 16 57 19.
                </p>

                <h4>1.3 Hébergeur (ci-après « l'hébergeur ») :</h4>

                <p>
                  Workload est hébergé par OVH, dont le siège social est situé 2
                  rue Kellermann 59100 Roubaix.
                </p>

                <h3>Article 2 - Accès au site</h3>

                <p>
                  L'accès au site et son utilisation sont réservés à un usage
                  strictement personnel. Vous vous engagez à ne pas utiliser ce
                  site et les informations ou données qui y figurent à des fins
                  commerciales, politiques, publicitaires et pour toute forme de
                  sollicitation commerciale et notamment l'envoi de courriers
                  électroniques non sollicités.
                </p>

                <h3>Article 3 - Contenu du site</h3>

                <p>
                  Toutes les marques, photographies, textes, commentaires,
                  illustrations, images animées ou non, séquences vidéo, sons,
                  ainsi que toutes les applications informatiques qui pourraient
                  être utilisées pour faire fonctionner ce site et plus
                  généralement tous les éléments reproduits ou utilisés sur le
                  site sont protégés par les lois en vigueur au titre de la
                  propriété intellectuelle.
                </p>
                <p>
                  Ils sont la propriété pleine et entière de l'éditeur ou de ses
                  partenaires. Toute reproduction, représentation, utilisation
                  ou adaptation, sous quelque forme que ce soit, de tout ou
                  partie de ces éléments, y compris les applications
                  informatiques, sans l'accord préalable et écrit de l'éditeur,
                  sont strictement interdites. Le fait pour l'éditeur de ne pas
                  engager de procédure dès la prise de connaissance de ces
                  utilisations non autorisées ne vaut pas acceptation desdites
                  utilisations et renonciation aux poursuites.
                </p>

                <h3>Article 4 - Gestion du site</h3>

                <p>
                  Pour la bonne gestion du site, l'éditeur pourra à tout moment
                  :
                </p>

                <ul>
                  <li>
                    suspendre, interrompre ou limiter l'accès à tout ou partie
                    du site, réserver l'accès au site, ou à certaines parties du
                    site, à une catégorie déterminée d'internautes ;
                  </li>
                  <li>
                    supprimer toute information pouvant en perturber le
                    fonctionnement ou entrant en contravention avec les lois
                    nationales ou internationales ;
                  </li>
                  <li>
                    suspendre le site afin de procéder à des mises à jour.
                  </li>
                </ul>

                <h3>Article 5 - Responsabilités</h3>

                <p>
                  La responsabilité de l'éditeur ne peut être engagée en cas de
                  défaillance, panne, difficulté ou interruption de
                  fonctionnement, empêchant l'accès au site ou à une de ses
                  fonctionnalités.
                </p>
                <p>
                  Le matériel de connexion au site que vous utilisez est sous
                  votre entière responsabilité. Vous devez prendre toutes les
                  mesures appropriées pour protéger votre matériel et vos
                  propres données notamment d'attaques virales par Internet.
                  Vous êtes par ailleurs seul responsable des sites et données
                  que vous consultez.
                </p>

                <p>
                  L'éditeur ne pourra être tenu responsable en cas de poursuites
                  judiciaires à votre encontre :
                </p>
                <ul>
                  <li>
                    du fait de l'usage du site ou de tout service accessible via
                    Internet ;
                  </li>
                  <li>
                    du fait du non-respect par vous des présentes conditions
                    générales.
                  </li>
                </ul>

                <p>
                  L'éditeur n'est pas responsable des dommages causés à
                  vous-même, à des tiers et/ou à votre équipement du fait de
                  votre connexion ou de votre utilisation du site et vous
                  renoncez à toute action contre lui de ce fait.
                </p>
                <p>
                  Si l'éditeur venait à faire l'objet d'une procédure amiable ou
                  judiciaire en raison de votre utilisation du site, il pourra
                  se retourner contre vous pour obtenir l'indemnisation de tous
                  les préjudices, sommes, condamnations et frais qui pourraient
                  découler de cette procédure.
                </p>

                <h3>Article 6 - Liens hypertextes</h3>

                <p>
                  La mise en place par les utilisateurs de tous liens
                  hypertextes vers tout ou partie du site est strictement
                  interdite, sauf autorisation préalable et écrite de l'éditeur.{" "}
                </p>
                <p>
                  L'éditeur est libre de refuser cette autorisation sans avoir à
                  justifier de quelque manière que ce soit sa décision. Dans le
                  cas où l'éditeur accorderait son autorisation, celle-ci n'est
                  dans tous les cas que temporaire et pourra être retirée à tout
                  moment, sans obligation de justification à la charge de
                  l'éditeur.
                </p>
                <p>
                  Toute information accessible via un lien vers d'autres sites
                  n'est pas publiée par l'éditeur. L'éditeur ne dispose d'aucun
                  droit sur le contenu présent dans ledit lien.{" "}
                </p>

                <h3>Article 7 - Cookies</h3>

                <p>Qu’est-ce qu’un « cookie » ?</p>
                <p>
                  Un « Cookie » ou traceur est un fichier électronique déposé
                  sur un terminal (ordinateur, tablette, smartphone,…) et lu par
                  exemple lors de la consultation d'un site internet, de la
                  lecture d'un courrier électronique, de l'installation ou de
                  l'utilisation d'un logiciel ou d'une application mobile et ce,
                  quel que soit le type de terminal utilisé (source :{" "}
                  <a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi">
                    https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi
                  </a>
                  ).
                </p>
                <p>
                  En naviguant sur ce site, des « cookies » émanant de la
                  société responsable du site concerné et/ou des sociétés tiers
                  pourront être déposés sur votre terminal.
                </p>
                <p>
                  Lors de la première navigation sur ce site, une bannière
                  explicative sur l’utilisation des « cookies » apparaîtra. Dès
                  lors, en poursuivant la navigation, le client et/ou prospect
                  sera réputé informé et avoir accepté l’utilisation desdits «
                  cookies ». Le consentement donné sera valable pour une période
                  de treize (13) mois. L'utilisateur a la possibilité de
                  désactiver les cookies à partir des paramètres de son
                  navigateur.
                </p>

                <p>
                  Toutes les informations collectées ne seront utilisées que
                  pour suivre le volume, le type et la configuration du trafic
                  utilisant ce site, pour en développer la conception et
                  l'agencement et à d'autres fins administratives et de
                  planification et plus généralement pour améliorer le service
                  que nous vous offrons.
                </p>

                <p>Les cookies suivants sont présents sur ce site : </p>

                <p>Cookies Google :</p>
                <ul>
                  <li>
                    Google analytics : permet de mesurer l'audience du site ;
                  </li>
                </ul>

                <p>La durée de vie de ces cookies est de treize mois.</p>

                <p>
                  Pour plus d’informations sur l’utilisation, la gestion et la
                  suppression des « cookies », pour tout type de navigateur,
                  nous vous invitons à consulter le lien suivant :{" "}
                  <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">
                    https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
                  </a>
                  .
                </p>

                <h3>
                  Article 8 - Photographies et représentation des produits
                </h3>

                <p>
                  Les photographies de produits, accompagnant leur description,
                  ne sont pas contractuelles et n'engagent pas l'éditeur.
                </p>

                <h3>Article 9 - Loi applicable</h3>

                <p>
                  Les présentes conditions d'utilisation du site sont régies par
                  la loi française et soumises à la compétence des tribunaux du
                  siège social de l'éditeur, sous réserve d'une attribution de
                  compétence spécifique découlant d'un texte de loi ou
                  réglementaire particulier.
                </p>

                <h3>Article 10 - Contactez-nous</h3>

                <p>
                  Pour toute question, information sur les produits présentés
                  sur le site, ou concernant le site lui-même, vous pouvez
                  laisser un message à l'adresse suivante :{" "}
                  <a href="mailto:contact@workload.fr">contact@workload.fr</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
