import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const baseUrl = process.env.REACT_APP_BASE_URL.replace(/\/$/, "");
const env = process.env.NODE_ENV;

let options = {
  saveMissing: "development" !== env,
  saveMissingTo: "current",
  lng: "fr",
  debug: "development" === env,
  interpolation: {
    escapeValue: false,
  },
};

options.backend = {
  addPath: `${baseUrl}/api/locales/add/{{lng}}/{{ns}}`,
  loadPath: `${baseUrl}/api/locales/{{lng}}/{{ns}}.json`,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init(options);

export default i18n;
