import React from "react";
import Scroll from "react-scroll";

export default function PageNotFound() {
  return (
    <div>
      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1>L'outil innovant de plan de charge</h1>
          <p>
            Pilotez efficacement <b>vos ressources (humaines / matérielles)</b>{" "}
            ainsi que <b>le temps passé</b> à l'aide du{" "}
            <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par
            plusieurs entreprises et chef de projet.
          </p>
          <p>
            Découvrez Workload, l'outil innovant de gestion de plan de charge.
          </p>
        </div>
      </div>

      <Scroll.Element name="main"></Scroll.Element>

      <section className="mt-120 mt-md-80 mt-sm-60 mb-120 mb-md-80 mb-sm-54">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title-wrap">
                <h2 className="mb-8">Page introuvable</h2>
                <p className="m-0">
                  Oups c'est embarassant, la page demandée est introuvable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
