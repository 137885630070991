import React from "react";
import CompanyForm from "./CompanyForm";
import Scroll from "react-scroll";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Company() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t(
            "home.company.title",
            "Workload - L'outil innovant de plan de charge"
          )}
        </title>
        <meta
          name="description"
          content={t(
            "home.company.description",
            "L'outil innovant de plan de charge"
          )}
        />
      </Helmet>

      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1 data-trans="company.intro.title">
            {t("company.intro.title", "L'outil innovant de plan de charge")}
          </h1>
          <p
            data-trans="company.intro.description"
            dangerouslySetInnerHTML={{
              __html: t(
                "company.intro.description",
                "Pilotez efficacement <b>vos ressources (humaines / matérielles)</b> ainsi que <b>le temps passé</b> à l'aide du <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par plusieurs entreprises et chef de projet."
              ),
            }}
          />

          <p
            data-trans="company.intro.sub_description"
            dangerouslySetInnerHTML={{
              __html: t(
                "company.intro.sub_description",
                "Découvrez Workload, l'outil innovant de gestion de plan de charge."
              ),
            }}
          />
        </div>
      </div>

      <Scroll.Element name="main"></Scroll.Element>

      <section className="mt-120 mt-md-80 mt-sm-60 mb-120 mb-md-80 mb-sm-54">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title-wrap">
                <h2 className="mb-8" data-trans="company.create_my_workload">
                  {t("company.create_my_workload", "Créer mon Plan de charge")}
                </h2>

                <p
                  className="m-0"
                  data-trans="company.content"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "company.content",
                      "Remplissez le formulaire ci-dessous afin de créer votre espace dédié."
                    ),
                  }}
                />
              </div>
              <CompanyForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
