import React from "react";
import Scroll from "react-scroll";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function WhoAreWe() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>
          {t(
            "home.who_are_we.title",
            "Workload - L'outil innovant de plan de charge"
          )}
        </title>
        <meta
          name="description"
          content={t(
            "home.who_are_we.description",
            "L'outil innovant de plan de charge"
          )}
        />
      </Helmet>

      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1 data-trans="who_are_we.intro.title">
            {t("who_are_we.intro.title", "L'outil innovant de plan de charge")}
          </h1>
          <p
            data-trans="who_are_we.intro.description"
            dangerouslySetInnerHTML={{
              __html: t(
                "who_are_we.intro.description",
                "Pilotez efficacement <b>vos ressources (humaines / matérielles)</b> ainsi que <b>le temps passé</b> à l'aide du <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par plusieurs entreprises et chef de projet."
              ),
            }}
          />

          <p
            data-trans="who_are_we.intro.sub_description"
            dangerouslySetInnerHTML={{
              __html: t(
                "who_are_we.intro.sub_description",
                "Découvrez Workload, l'outil innovant de gestion de plan de charge."
              ),
            }}
          />
        </div>
      </div>

      <Scroll.Element name="main"></Scroll.Element>

      <section className="welcome-section mt-120 mt-md-80 mt-sm-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div className="section-title-wrap">
                <h2 data-trans="who_are_we.title">
                  {t("who_are_we.title", "Qui sommes nous ?")}
                </h2>

                <div
                  data-trans="who_are_we.content"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "who_are_we.content",
                      '<p class="mb-0">\n' +
                        "                  Une société innovante souhaitant aider les entreprises à gérer\n" +
                        "                  leurs ressources humaines et améliorer les relations entre\n" +
                        "                  collaborateurs.\n" +
                        "                </p>\n" +
                        '                <p class="mb-0 mt-20">\n' +
                        "                  Lors de nos missions, nous n'étions pas en mesure d'évaluer\n" +
                        "                  correctement les tâches que réalisaient nos ressources et leur\n" +
                        "                  niveau de charge. L'affectation des tâches étaient souvent\n" +
                        "                  empirique et non réaliste. Les projets étaient en péril et les\n" +
                        "                  ressources mécontentes car surchargées.\n" +
                        "                </p>\n" +
                        '                <p class="mb-0 mt-20">\n' +
                        "                  La méthode agile est basée sur une vision projet mais elle\n" +
                        "                  n'offre pas une vision globale sur l'ensemble des\n" +
                        "                  collaborateurs.\n" +
                        "                </p>\n" +
                        '                <p class="mb-0 mt-20">\n' +
                        "                  Ce projet qui à demandé des centaines de jours de\n" +
                        "                  développement est gratuit, en constante amélioration, mais\n" +
                        "                  limité à 5 utilisateurs par environnement pour le moment.\n" +
                        "                </p>"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
