import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import { Helmet } from "react-helmet/es/Helmet";
import { withTranslation } from "react-i18next";
import Scroll from "react-scroll";

class Content extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      content: null,
    };

    this.getContent = this.getContent.bind(this);
  }

  componentWillMount() {
    this.getContent();
  }

  getContent() {
    ModelManager.fetchList("/api/contents", { slug: this.props.slug }, false)
      .then((res) => {
        if (res.getCount() > 0) {
          this.setState({ content: res.getMembers()[0] });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <div>
        <div className="slider-area-wrapper fix">
          <img
            src="/assets/img/home-classic-agency/slider/01.jpg"
            alt="Slider"
            data-bgposition="center center"
            data-bgfit="cover"
            data-bgrepeat="no-repeat"
            className="rev-slidebg"
            data-no-retina
          />
          <div className="content col-md-6">
            <h1 data-trans="content.intro.title">
              {this.props.t(
                "content.intro.title",
                "L'outil innovant de plan de charge"
              )}
            </h1>
            <p
              data-trans="content.intro.description"
              dangerouslySetInnerHTML={{
                __html: this.props.t(
                  "content.intro.description",
                  "Pilotez efficacement <b>vos ressources (humaines / matérielles)</b> ainsi que <b>le temps passé</b> à l'aide du <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par plusieurs entreprises et chef de projet."
                ),
              }}
            />

            <p
              data-trans="content.intro.sub_description"
              dangerouslySetInnerHTML={{
                __html: this.props.t(
                  "content.intro.sub_description",
                  "Découvrez Workload, l'outil innovant de gestion de plan de charge."
                ),
              }}
            />
          </div>
        </div>

        <Scroll.Element name="main"></Scroll.Element>

        <section className="welcome-section mt-120 mt-md-80 mt-sm-60">
          <div className="container">
            <div className="row">
              {!this.state.isLoading && !this.state.content && (
                <div className="col-lg-6 m-auto">
                  <div className="section-title-wrap">
                    <h2 className="mb-8">Page introuvable</h2>
                    <div>Page introuvable</div>
                  </div>
                </div>
              )}
              {this.state.isLoading && (
                <div className="loading">
                  <img src="/loading.gif" alt="loading" />
                </div>
              )}
              {this.state.content && (
                <>
                  <Helmet>
                    <title>Workload - {this.state.content.title}</title>
                    <meta
                      name="title"
                      content={`Workload - ${this.state.content.title}`}
                    />
                    {this.state.content.description && (
                      <meta
                        name="description"
                        content={this.state.content.description}
                      />
                    )}
                  </Helmet>

                  <div className="col-lg-6 m-auto">
                    <div className="section-title-wrap">
                      <h2 className="mb-8">{this.state.content.title}</h2>
                      <div
                        className="m-0"
                        data-trans="home.intro.description"
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.content,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(Content);
