import React from "react";
import { ModelManager } from "@mjcom/model-manager";
import { withTranslation } from "react-i18next";
import Scroll from "react-scroll";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";

class Contents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: [],
      activePage: 1,
      isLoading: true,
    };

    this.getContents = this.getContents.bind(this);
  }

  componentWillMount() {
    this.getContents();
  }

  getContents(page = 1) {
    ModelManager.fetchList("/api/contents", { page })
      .then((collection) => {
        this.setState({
          elements: collection.getMembers(),
          totalItems: collection.getTotalItems(),
          activePage: page,
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const elements =
      this.state.elements.length > 0
        ? this.state.elements.map((element) => (
            <li
              key={element.id}
              className={element.remaining === 0 ? "inactive" : ""}
            >
              <Link to={`/content/${element.slug}`}>{element.title}</Link>
            </li>
          ))
        : "";

    return (
      <div>
        <div className="slider-area-wrapper fix">
          <img
            src="/assets/img/home-classic-agency/slider/01.jpg"
            alt="Slider"
            data-bgposition="center center"
            data-bgfit="cover"
            data-bgrepeat="no-repeat"
            className="rev-slidebg"
            data-no-retina
          />
          <div className="content col-md-6">
            <h1 data-trans="contents.intro.title">
              {this.props.t(
                "contents.intro.title",
                "L'outil innovant de plan de charge"
              )}
            </h1>
            <p
              data-trans="contents.intro.description"
              dangerouslySetInnerHTML={{
                __html: this.props.t(
                  "contents.intro.description",
                  "Pilotez efficacement <b>vos ressources (humaines / matérielles)</b> ainsi que <b>le temps passé</b> à l'aide du <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par plusieurs entreprises et chef de projet."
                ),
              }}
            />

            <p
              data-trans="contents.intro.sub_description"
              dangerouslySetInnerHTML={{
                __html: this.props.t(
                  "contents.intro.sub_description",
                  "Découvrez Workload, l'outil innovant de gestion de plan de charge."
                ),
              }}
            />
          </div>
        </div>

        <Scroll.Element name="main"></Scroll.Element>

        <section className="welcome-section mt-120 mt-md-80 mt-sm-60">
          <div className="container">
            <div className="row">
              <Helmet>
                <title>
                  {this.props.t(
                    "contents.header.title",
                    "Workload - L'outil innovant de plan de charge"
                  )}
                </title>
                <meta
                  name="description"
                  content={this.props.t(
                    "contents.header.description",
                    "L'outil innovant de plan de charge"
                  )}
                />
              </Helmet>

              <div className="col-lg-6 m-auto">
                <div className="section-title-wrap">
                  <h2 data-trans="contents.intro.title">
                    {this.props.t("contents.intro.title", "Nos articles")}
                  </h2>

                  {!this.state.isLoading ? (
                    <div>
                      <ul>{elements}</ul>

                      {this.state.totalItems >
                      process.env.REACT_APP_PER_PAGE ? (
                        <nav className="pagination">
                          <Pagination
                            disabledClass="disabled"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.activePage}
                            itemsCountPerPage={parseInt(
                              process.env.REACT_APP_PER_PAGE
                            )}
                            totalItemsCount={this.state.totalItems}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                          />
                        </nav>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="loading">
                      <img src="/loading.gif" alt="loading" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(Contents);
