import React from "react";
import Scroll from "react-scroll";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Faq() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>
          {t(
            "faq.header.title",
            "Workload - L'outil innovant de plan de charge"
          )}
        </title>
        <meta
          name="description"
          content={t(
            "faq.header.description",
            "L'outil innovant de plan de charge"
          )}
        />
      </Helmet>

      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1 data-trans="faq.intro.title">
            {t("faq.intro.title", "L'outil innovant de plan de charge")}
          </h1>
          <p
            data-trans="faq.intro.description"
            dangerouslySetInnerHTML={{
              __html: t(
                "faq.intro.description",
                "Pilotez efficacement <b>vos ressources (humaines / matérielles)</b> ainsi que <b>le temps passé</b> à l'aide du <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par plusieurs entreprises et chef de projet."
              ),
            }}
          />

          <p
            data-trans="faq.intro.sub_description"
            dangerouslySetInnerHTML={{
              __html: t(
                "faq.intro.sub_description",
                "Découvrez Workload, l'outil innovant de gestion de plan de charge."
              ),
            }}
          />
        </div>
      </div>

      <Scroll.Element name="main"></Scroll.Element>

      <section className="welcome-section mt-120 mt-md-80 mt-sm-60">
        <div className="container">
          <div className="row">
            <h2>{t("faq.title", "FAQ")}</h2>
            <div
              data-trans="faq.content"
              dangerouslySetInnerHTML={{
                __html: t(
                  "faq.content",
                  '<div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Qui sommes nous ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  Nous avons conçu Workload plan de charge pour vous simplifier" +
                    "                  les tâches lourdes et fastidieuses en gestion de projet." +
                    "                  Workload est une solution collaborative par excellence créé" +
                    "                  par la société JUERS. L’interface Workload offre un dashboard" +
                    "                  complet où gestion des tâches, gestions des ressources et" +
                    "                  planifications / tableaux de bords sont accessibles d’un" +
                    "                  simple clic et sans aucune complexité." +
                    "                </p>" +
                    '                <p class="mb-0">' +
                    "                  Nous évoluons constamment notre logiciel de planning de charge" +
                    "                  Workload pour y ajouter des fonctionnalités qui vous" +
                    "                  facilitent la vie de gestion de projet quotidienne." +
                    "                </p>" +
                    '                <p class="mb-0">' +
                    "                  Tout de même, notre super support reste à votre écoute pour" +
                    "                  toute interrogation ou étude d’évolution spécifique." +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>A quoi sert le plan de charge ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  Le plan de charge assure un suivi des ressources humaines." +
                    "                  <br />" +
                    "                  En quelques secondes vous pouvez voir la charge de vos" +
                    "                  collaborateurs:" +
                    "                  <br />" +
                    "                  <br />" +
                    "                  <ul>" +
                    "                    <li>" +
                    "                      Savoir si vous pouvez faire rentrer de nouveaux projets," +
                    "                    </li>" +
                    "                    <li>" +
                    "                      savoir si vous devez recruter de nouvelles ressources," +
                    "                    </li>" +
                    "                    <li>savoir si vous devez sous-traiter,</li>" +
                    "                    <li>savoir si votre projet est en danger,</li>" +
                    "                    <li>savoir si vous devez prioriser certaines tâches,</li>" +
                    "                    <li>identifier les tâches risquées,</li>" +
                    "                    <li>identifier les ressources surchargées ou libres,</li>" +
                    "                    <li>et bien plus ...</li>" +
                    "                  </ul>" +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Qui est concerné ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  A partir du moment ou vous devez suivre une ressource humaine," +
                    "                  vous êtes concerné." +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Combien coûte Workload ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  L'outil est entièrement gratuit, aucun moyen de paiement ne" +
                    "                  vous sera demandé." +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Ya t-il des limitations sur la version gratuite ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  L’unique limitation de Workload plan de charge est le nombre" +
                    "                  d'utilisateur par espace de travail. En effet, Workload est" +
                    "                  limité à 5 utilisateurs afin de ne pas saturer nos serveurs." +
                    "                  Dépassé ce nombre d’utilisateur, notre support vous contact" +
                    "                  pour analyser plus spécifiquement vos besoins en ressources et" +
                    '                  vous proposer la meilleure configuration pour votre société.{" "}' +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Ya t-il une période d'essai ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  Workload vous offre la possibilité de créer votre compte" +
                    "                  immédiatement et commencer à utiliser l’ensemble des" +
                    "                  fonctionnalités directement. Aucune période d'essai ni aucun" +
                    "                  frais caché, vous pouvez utiliser Workload sans limite de" +
                    '                  temps et cela de façon immédiate.{" "}' +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Workload va t-il évoluer ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  Oui, Workload évolue chaque jour. De nouvelles fonctionnalités" +
                    "                  continuent de sortir régulièrement." +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Que sait faire Workload ?</h3>" +
                    '                <p class="mb-0">' +
                    "                  Voici la liste non exhaustive de ce que sait faire Workload:" +
                    "                  <br />" +
                    "                  <br />" +
                    "                  <ul>" +
                    "                    <li>Gestion des tâches,</li>" +
                    "                    <li>gestion des projets,</li>" +
                    "                    <li>gestion des utilisateurs,</li>" +
                    "                    <li>" +
                    "                      gestion des rôles utilisateur (Développeur / Chef de" +
                    "                      projet / Manager / Administrateur)," +
                    "                    </li>" +
                    "                    <li>gestion des absences,</li>" +
                    "                    <li>gestion des jours fériés,</li>" +
                    "                    <li>gestion des types de tâches,</li>" +
                    "                    <li>gestion des types de tâches,</li>" +
                    "                    <li>et bien plus ...</li>" +
                    "                  </ul>" +
                    "                </p>" +
                    "              </div>" +
                    "            </div>" +
                    "          </div>" +
                    "" +
                    '          <div class="row">' +
                    '            <div class="col-lg-6 m-auto">' +
                    '              <div class="section-title-wrap">' +
                    "                <h3>Comment utiliser Workload Plan de charge ?</h3>" +
                    '                <p class="mb-0">' +
                    '                  Il vous suffit de cliquer sur le lien en haut de la page ou de{" "}' +
                    '                  <Link to="/try-it">' +
                    "                    cliquez ici pour essayer gratuitement Workload et sans durée" +
                    "                    limitée" +
                    "                  </Link>" +
                    "                  ." +
                    "                </p>" +
                    "              </div>" +
                    "            </div>"
                ),
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
