import React, { Suspense } from "react";
import "./App.css";
import { Router, Switch, Route, Link } from "react-router-dom";
import Home from "./Component/Home/Home";
import WhoAreWe from "./Component/WhoAreWe/WhoAreWe";
import Company from "./Component/Company/Company";
import Congratulation from "./Component/Congratulation/Congratulation";
import Contact from "./Component/Contact/Contact";
import LegalNotice from "./Component/LegalNotice/LegalNotice";
import Faq from "./Component/Faq/Faq";
import ScrollToTop from "./Component/ScrollToTop/ScrollToTop";
import { history } from "./History";
import PageNotFound from "./Component/PageNotFound/PageNotFound";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import "./i18n";
import Content from "./Component/Content/Content";
import Contents from "./Component/Content/Contents";

function App() {
  return (
    <Router history={history}>
      <header className="header-area-wrapper transparent-header sticky-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-lg-2">
              <a href="/" className="logo-wrap d-block">
                <img src="/assets/img/logo-white.png" alt="White Logo" />{" "}
                <img
                  src="/assets/img/logo-black.png"
                  className="sticky-logo"
                  alt="Black Logo"
                />
              </a>
            </div>
            <div className="col-6 col-lg-10 my-auto ml-auto position-static">
              <div className="header-right-area d-flex justify-content-end align-items-center">
                <div className="navigation-area-wrap d-none d-lg-block">
                  <nav className="main-navigation">
                    <ul className="main-menu nav justify-content-end">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/who-are-we">Qui sommes nous ?</Link>
                      </li>
                      <li>
                        <Link to="/try-it">Essayer Workload gratuitement</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="off-canvas-area-wrap nav">
                  <button className="search-box-open d-block d-lg-none">
                    <i className="fa fa-search"></i>
                  </button>
                  <button className="off-canvas-btn d-none d-lg-block">
                    <i className="fa fa-bars"></i>
                  </button>
                  <button className="mobile-menu text-white d-lg-none">
                    <i className="fa fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <ScrollToTop />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route
              exact
              path="/content/:slug"
              render={(props) => (
                <Content slug={props.match.params.slug} {...props} />
              )}
            />
            <Route exact path="/contents">
              <Contents />
            </Route>
            <Route exact path="/try-it">
              <Company />
            </Route>
            <Route exact path="/congratulation">
              <Congratulation />
            </Route>
            <Route exact path="/who-are-we">
              <WhoAreWe />
            </Route>
            <Route exact path="/faq">
              <Faq />
            </Route>
            <Route exact path="/legal-notice">
              <LegalNotice />
            </Route>
            <Route exact path="/faq">
              <Faq />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </div>
      <footer className="footer-wrapper">
        <div className="footer-widget-wrapper pt-120 pt-md-80 pt-sm-60 pb-116 pb-md-78 pb-sm-60">
          <div className="container">
            <div className="row mtm-44">
              <div className="col-md-6">
                <div className="single-widget-wrap">
                  <h3 className="widget-title">Le site</h3>
                  <div className="widget-body">
                    <div className="about-text">
                      <address>Workload</address>
                      <a href="mailto:contact@workload.fr">
                        Email: contact@workload.fr
                      </a>
                      <br />
                      <a
                        href="https://workload.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        workload.fr
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-widget-wrap">
                  <h3 className="widget-title">Liens utiles</h3>
                  <div className="widget-body">
                    <ul className="widget-list">
                      <li>
                        <Link to="/legal-notice">Mentions légales</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">
                          Politique de confidentialité
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-7 order-last">
                <div className="footer-copyright-area mt-xs-10 text-center text-sm-left">
                  <p>Copyright © 2019 Workload - Tout droits réservés.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-5 order-first order-sm-last">
                <div className="footer-social-icons nav justify-content-center justify-content-md-end"></div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <aside className="off-canvas-area-wrapper">
        <div className="off-canvas-overlay"></div>
        <div className="off-canvas-content-wrap">
          <div className="off-canvas-content">
            <div className="search-box-wrap off-canvas-item"></div>
            <div className="about-content off-canvas-item">
              <h2>Qui sommes nous ?</h2>
              <p>
                Une société innovante spécialisée dans les gestion de ressources
                humaines, plan de charge.
              </p>
            </div>
            <div className="useful-link-wrap off-canvas-item">
              <h2>Liens utiles</h2>
              <ul className="useful-link-menu">
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/legal-notice">Mentions légales</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Politique de confidentialité</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/contents">Nos articles</Link>
                </li>
              </ul>
            </div>
          </div>
          <button
            className="btn-close trio-tooltip"
            data-tippy-content="Close"
            data-tippy-placement="left"
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
      </aside>
      <aside className="off-canvas-responsive-menu">
        <div className="off-canvas-overlay"></div>
        <div className="off-canvas-content-wrap">
          <div className="off-canvas-content"></div>
          <button
            className="btn-close trio-tooltip"
            data-tippy-content="Close"
            data-tippy-placement="right"
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
      </aside>
      <aside className="off-canvas-search-box">
        <div className="off-canvas-overlay"></div>
        <div className="search-box-wrapper text-center">
          <div className="search-box-content">
            <form action="#" method="post">
              <input type="search" placeholder="Search" />
              <button className="btn-search">
                <i className="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </aside>
    </Router>
  );
}

export default App;
