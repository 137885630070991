import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scroll from "react-scroll";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    Scroll.scroller.scrollTo("main", {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 0,
    });
  }, [pathname]);

  return null;
}
