import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Home() {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Helmet>
        <title>
          {t(
            "home.header.title",
            "Workload - L'outil innovant de plan de charge"
          )}
        </title>
        <meta
          name="description"
          content={t(
            "home.header.description",
            "L'outil innovant de plan de charge"
          )}
        />
      </Helmet>

      <div className="slider-area-wrapper fix">
        <img
          src="/assets/img/home-classic-agency/slider/01.jpg"
          alt="Slider"
          data-bgposition="center center"
          data-bgfit="cover"
          data-bgrepeat="no-repeat"
          className="rev-slidebg"
          data-no-retina
        />
        <div className="content col-md-6">
          <h1 data-trans="home.intro.title">
            {t("home.intro.title", "L'outil innovant de plan de charge")}
          </h1>
          <p
            data-trans="home.intro.description"
            dangerouslySetInnerHTML={{
              __html: t(
                "home.intro.description",
                "Pilotez efficacement <b>vos ressources (humaines / matérielles)</b> ainsi que <b>le temps passé</b> à l'aide du <b>logiciel de plan de charge en ligne & gratuit</b> privilégié par plusieurs entreprises et chef de projet."
              ),
            }}
          />

          <p
            data-trans="home.intro.sub_description"
            dangerouslySetInnerHTML={{
              __html: t(
                "home.intro.sub_description",
                "Découvrez Workload, l'outil innovant de gestion de plan de charge."
              ),
            }}
          />
        </div>
      </div>

      <section className="welcome-section mt-120 mt-md-80 mt-sm-60">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <h2 data-trans="home.first_row.col1.title">
                      {t(
                        "home.first_row.col1.title",
                        "Workload votre outil de plan de charge"
                      )}
                    </h2>
                    <div
                      data-trans="home.first_row.col1.content"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "home.first_row.col1.content",
                          "<p>" +
                            "                      Workload est un puissant" +
                            "                      <b>outil de gestion de plan de charge</b> configurable et" +
                            "                      ajustable en fonction de vos paramètres métiers &" +
                            "                      activités.." +
                            "                    </p>" +
                            "                    <p>" +
                            "                      Avec votre plan de charge, gérez efficacement et" +
                            "                      visualisez <b>le macro planning de vos ressources</b>," +
                            "                      anticipez les périodes de creux ou celles surchargées." +
                            "                    </p>"
                        ),
                      }}
                    />
                    <p>
                      <Link to="/try-it">Essayer dès maintenant</Link>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h2 data-trans="home.first_row.col2.title">
                      {t(
                        "home.first_row.col2.title",
                        "Ce que vous pouvez faire avec notre outil de plan de charge"
                      )}
                    </h2>

                    <div
                      data-trans="home.first_row.col2.content"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "home.first_row.col2.content",
                          "<p>" +
                            "                      Avec Workload plan de charge, disposez d’une" +
                            "                      <b>" +
                            "                        synthèse des temps passés et planifiés par collaborateur" +
                            "                        et par projet" +
                            "                      </b>" +
                            "                      puis <b>dégager des bénéfices</b> certains pour vous et" +
                            "                      pour votre entreprise." +
                            "                    </p>" +
                            "                    <p>" +
                            "                      Mettre en place un plan de charge devient une tâche" +
                            "                      facile, <b>anticipez une charge de travail</b>importante" +
                            "                      et" +
                            "                      <b>affecter les collaborateurs aux différents projets</b>." +
                            "                    </p>" +
                            "                    <p>" +
                            "                      Ainsi, pour chaque projet présent dans le plan de charge," +
                            "                      une période de temps est définie. Il est possible de" +
                            "                      réajuster cette planification en fonction des différents" +
                            "                      aléas que peuvent rencontrer vos collaborateurs. De cette" +
                            "                      façon, tout est pris en compte dans la" +
                            "                      <b>gestion de votre projet</b> et cela est un gain de" +
                            "                      réassurance via à vis de votre client & hiérarchie." +
                            "                    </p>"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <figure className="welcome-thumbnail">
                <img
                  src="/assets/img/home-classic-agency/about-classic-agency.png"
                  alt="Workload example"
                />
              </figure>
            </div>
            <div className="col-lg-8">
              <div className="welcome-feature mt-sm-32 mt-md-36">
                <div>
                  <h2 data-trans="home.why.title">
                    {t("home.why.title", "Pourquoi choisir Workload ?")}
                  </h2>

                  <div
                    className="row"
                    data-trans="home.why.content"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "home.why.content",
                        '                  <div class="col-md-6">' +
                          '                    <div class="single-welcome-feature d-flex">' +
                          '                      <div class="feature-icon">' +
                          '                        <i class="fa fa-codepen"></i>' +
                          "                      </div>" +
                          '                      <div class="feature-info">' +
                          "                        <h3>Gratuit et sans limitation de durée</h3>" +
                          "                        <p>" +
                          "                          Notre outil de plan de charge est" +
                          "                          <b>entièrement gratuit</b> jusqu'à 5 utilisateurs et" +
                          "                          ceci <b>sans aucune durée limitée</b>." +
                          "                        </p>" +
                          "                      </div>" +
                          "                    </div>" +
                          "                  </div>" +
                          '                  <div class="col-md-6">' +
                          '                    <div class="single-welcome-feature d-flex">' +
                          '                      <div class="feature-icon">' +
                          '                        <i class="fa fa-codepen"></i>' +
                          "                      </div>" +
                          '                      <div class="feature-info">' +
                          "                        <h3>Un outil de plan de charge complet</h3>" +
                          "                        <p>" +
                          "                          Bénéficiez d'une" +
                          "                          <b>" +
                          "                            solution de gestion de plan de charge tout-en-un" +
                          "                          </b>" +
                          "                          entièrement modulable en fonction de vos besoins" +
                          "                          métiers." +
                          "                        </p>" +
                          "                      </div>" +
                          "                    </div>" +
                          "                  </div>" +
                          '                  <div class="col-md-6">' +
                          '                    <div class="single-welcome-feature d-flex">' +
                          '                      <div class="feature-icon">' +
                          '                        <i class="fa fa-file-o"></i>' +
                          "                      </div>" +
                          '                      <div class="feature-info">' +
                          "                        <h3>Design moderne et responsive</h3>" +
                          "                        <p>" +
                          '                          Workload est optimisé pour tous les "devices". Son' +
                          "                          design ergonomique le" +
                          "                          <b>rend accessible partout où vous allez</b>." +
                          "                        </p>" +
                          "                      </div>" +
                          "                    </div>" +
                          "                  </div>" +
                          '                  <div class="col-md-6">' +
                          '                    <div class="single-welcome-feature d-flex">' +
                          '                      <div class="feature-icon">' +
                          '                        <i class="fa fa-folder-open-o"></i>' +
                          "                      </div>" +
                          '                      <div class="feature-info">' +
                          "                        <h3>Personnalisez votre expérience</h3>" +
                          "                        <p>" +
                          "                          Workload est entièrement configurable, vous pouvez le" +
                          "                          paramétrer en fonction du" +
                          "                          <b>besoin de votre société & son activité</b> pour une" +
                          "                          gestion de plan de charge à son maximum d'efficacité." +
                          "                        </p>" +
                          "                      </div>" +
                          "                    </div>" +
                          "                  </div>" +
                          "" +
                          '                  <div class="col-md-6">' +
                          '                    <div class="single-welcome-feature d-flex">' +
                          '                      <div class="feature-icon">' +
                          '                        <i class="fa fa-diamond"></i>' +
                          "                      </div>" +
                          '                      <div class="feature-info">' +
                          "                        <h3>" +
                          "                          Gestion des congés, indisponibilités et taux" +
                          "                          d'occupation des équipes" +
                          "                        </h3>" +
                          "                        <p>" +
                          "                          Grâce à sa gestion de congés, vous visualisez en temps" +
                          "                          réel les vacances, indisponibilité ou les taux" +
                          "                          d'occupation de vos collaborateurs sur le plan de" +
                          "                          charge de façon native." +
                          "                        </p>" +
                          "                      </div>" +
                          "                    </div>" +
                          "                  </div>" +
                          '                  <div class="col-md-6">' +
                          '                    <div class="single-welcome-feature d-flex">' +
                          '                      <div class="feature-icon">' +
                          '                        <i class="fa fa-diamond"></i>' +
                          "                      </div>" +
                          '                      <div class="feature-info">' +
                          "                        <h3>Une gestion de projet innovante & précise</h3>" +
                          "                        <p>" +
                          "                          Workload génère automatiquement un rapport et" +
                          "                          détermine votre progression via un diagramme de Gantt" +
                          "                          et vous détail votre projet par tâche." +
                          "                        </p>" +
                          "                      </div>" +
                          "                    </div>"
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fun-fact-area-wrapper mt-120 mt-md-62 mt-sm-42">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-6 col-lg-3 text-center">
              <div
                className="single-fun-fact-wrap bg-img"
                data-bg="/assets/img/home-classic-agency/fun-fact/01.jpg"
              >
                <p className="fun-fact-number">
                  <span>90</span>%
                </p>
                <h4>de taux de satisfaction clients</h4>
              </div>
            </div>

            <div className="col-6 col-lg-3 text-center">
              <div
                className="single-fun-fact-wrap bg-img"
                data-bg="/assets/img/home-classic-agency/fun-fact/02.jpg"
              >
                <p className="fun-fact-number">
                  <span className="odometer">10</span>%
                </p>
                <h4>de temps facturable par collaborateur</h4>
              </div>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <div
                className="single-fun-fact-wrap bg-img"
                data-bg="/assets/img/home-classic-agency/fun-fact/03.jpg"
              >
                <p className="fun-fact-number">
                  <span className="odometer">30</span>%
                </p>
                <h4>de temps de planification gagné</h4>
              </div>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <div
                className="single-fun-fact-wrap bg-img"
                data-bg="/assets/img/home-classic-agency/fun-fact/04.jpg"
              >
                <p className="fun-fact-number">
                  <span className="odometer">250</span>
                </p>
                <h4>projets réussis</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio-area-wrapper mt-120 mt-md-80 mt-sm-60">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto text-center">
              <div className="section-title-wrap">
                <h3 data-trans="home.function.title">
                  {t("home.function.title", "Fonctionnalités")}
                </h3>
                <p className="mb-0">
                  {t(
                    "home.function.description",
                    "L'outil de plan de charge le plus complet ! Découvrez un extrait des fonctionnalités accessibles via le plan de charge !"
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="row"
                data-trans="home.function.content"
                dangerouslySetInnerHTML={{
                  __html: t(
                    "home.function.content",
                    "              <ul>" +
                      "                <li>" +
                      "                  - Visualiser le taux d'occupation de vos ressources humaines," +
                      "                </li>" +
                      "                <li>- Gestion des membres / droits,</li>" +
                      "                <li>- Gestion du planning,</li>" +
                      "                <li>- Gestion des absences (congés / maladies / RTT),</li>" +
                      "                <li>" +
                      "                  - Gestion de la périodicité, type de tâche, ratio" +
                      "                  d'utilisation," +
                      "                </li>" +
                      "                <li>" +
                      "                  - Détails d'un projet avec les informations importantes et" +
                      "                  génération en live du diagramme de Gantt," +
                      "                </li>" +
                      "                <li>- Et plus encore...</li>" +
                      "              </ul>"
                  ),
                }}
              />
            </div>
          </div>
          <div className="row masonry-grid mtm-30">
            <div className="col-lg-4 col-sm-6">
              <div className="single-portfolio-wrap">
                <figure className="portfolio-thumb">
                  <img
                    src="/assets/img/workload/examples/01.jpg"
                    alt="Workload"
                  />
                  <figcaption className="portfolio-details">
                    <div className="port-info">
                      <h3>
                        <a href="/try-it">Taux d'occupation</a>
                      </h3>
                      <nav className="nav portfolio-cate">
                        <a href="/try-it">
                          Visualiser le taux d'occupation de vos ressources
                          humaines.
                        </a>
                      </nav>
                    </div>
                    <a href="/try-it" className="btn-view-work">
                      Essayer workload
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-portfolio-wrap">
                <figure className="portfolio-thumb">
                  <img
                    src="/assets/img/workload/examples/02.jpg"
                    alt="Workload"
                  />
                  <figcaption className="portfolio-details">
                    <div className="port-info">
                      <h3>
                        <a href="/try-it">Gestion des membres / droits</a>
                      </h3>
                      <nav className="nav portfolio-cate">
                        <a href="/try-it">
                          Gestion des droits avancés (Développeur, Chef de
                          projet, manager, admin) avec des vues différentes.
                        </a>
                      </nav>
                    </div>
                    <a href="/try-it" className="btn-view-work">
                      Essayer workload
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-portfolio-wrap">
                <figure className="portfolio-thumb">
                  <img
                    src="/assets/img/workload/examples/03.jpg"
                    alt="Workload"
                  />
                  <figcaption className="portfolio-details">
                    <div className="port-info">
                      <h3>
                        <a href="/try-it">Gestion du planning</a>
                      </h3>
                      <nav className="nav portfolio-cate">
                        <a href="/try-it">
                          Planning avancé par jours / utilisateurs / tâches en
                          un coup d'oeil.
                        </a>
                      </nav>
                    </div>
                    <a href="/try-it" className="btn-view-work">
                      Essayer workload
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-portfolio-wrap">
                <figure className="portfolio-thumb">
                  <img
                    src="/assets/img/workload/examples/04.jpg"
                    alt="Workload"
                  />
                  <figcaption className="portfolio-details">
                    <div className="port-info">
                      <h3>
                        <a href="/try-it">
                          Gestion des absences (congés / maladies / RTT)
                        </a>
                      </h3>
                      <nav className="nav portfolio-cate">
                        <a href="/try-it">
                          Les jours d'absences sont intégrés dans le calcul du
                          plan de charges.
                        </a>
                      </nav>
                    </div>
                    <a href="/try-it" className="btn-view-work">
                      Essayer workload
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-portfolio-wrap">
                <figure className="portfolio-thumb">
                  <img
                    src="/assets/img/workload/examples/05.jpg"
                    alt="Workload"
                  />
                  <figcaption className="portfolio-details">
                    <div className="port-info">
                      <h3>
                        <a href="/try-it">Ajout d'une tâche</a>
                      </h3>
                      <nav className="nav portfolio-cate">
                        <a href="/try-it">
                          Gestion de la périodicité, type de tâche, ratio
                          d'utilisation.
                        </a>
                      </nav>
                    </div>
                    <a href="/try-it" className="btn-view-work">
                      Essayer workload
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-portfolio-wrap">
                <figure className="portfolio-thumb">
                  <img
                    src="/assets/img/workload/examples/06.jpg"
                    alt="Workload"
                  />
                  <figcaption className="portfolio-details">
                    <div className="port-info">
                      <h3>
                        <a href="/try-it">Affichage par projet</a>
                      </h3>
                      <nav className="nav portfolio-cate">
                        <a href="/try-it">
                          Détails d'un projet avec les informations importantes
                          et le diagramme de Gantt.
                        </a>
                      </nav>
                    </div>
                    <a href="/try-it" className="btn-view-work">
                      Essayer workload
                      <i className="fa fa-long-arrow-right"></i>
                    </a>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="testimonial-area bg-img mt-120 mt-md-80 mt-sm-60"
        data-bg="/assets/img/home-classic-agency/testimonials/classic-agency-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <Carousel
              controls={false}
              activeIndex={index}
              onSelect={handleSelect}
              slide={false}
              interval={20000}
            >
              <Carousel.Item className="single-testimonial-wrap layout--2">
                <div className="row">
                  <figure className="author-thumb col-md-2">
                    <img
                      src="/assets/img/home-classic-agency/testimonials/01.jpg"
                      alt="Workload"
                    />
                  </figure>
                  <p className="col-md-10">
                    <sup className="quote-icon">“</sup>Avec Workload, 90% des
                    projets métiers ont respecté la date de livraison prévue
                    sans parler des gains budgétaires.
                    <sup className="quote-icon-right">”</sup>
                  </p>
                </div>
                <h3 className="client-name">
                  Nicolas G.
                  <span className="client-designation">
                    Agence de communication
                  </span>
                </h3>
              </Carousel.Item>

              <Carousel.Item className="single-testimonial-wrap layout--2">
                <div className="row">
                  <figure className="author-thumb col-md-2">
                    <img
                      src="/assets/img/home-classic-agency/testimonials/02.jpg"
                      alt="Workload"
                    />
                  </figure>
                  <p className="col-md-10">
                    <sup className="quote-icon">“</sup>Nous ne voulions pas d’un
                    outil classique et trop complexe où l’on se sert pas de
                    toutes les fonctionnalités... Notre besoin est une simple
                    application pour gérer notre plan de charge avec une vue
                    d’ensemble. Grâce à Workload, le Job est 100% réussi.
                    <sup className="quote-icon-right">”</sup>
                  </p>
                </div>
                <h3 className="client-name">
                  Hennet B.
                  <span className="client-designation">My Mother Agency</span>
                </h3>
              </Carousel.Item>

              <Carousel.Item className="single-testimonial-wrap layout--2">
                <div className="row">
                  <figure className="author-thumb col-md-2">
                    <img
                      src="/assets/img/home-classic-agency/testimonials/03.jpg"
                      alt="Workload"
                    />
                  </figure>
                  <p className="col-md-10">
                    <sup className="quote-icon">“</sup>Avec Workload, nous avons
                    enfin une vision direct sur toutes nos ressources humaines.
                    Ceci nous aide au quotidien et donne autre sens à la gestion
                    d'équipe.<sup className="quote-icon-right">”</sup>
                  </p>
                </div>
                <h3 className="client-name">
                  Fleurquin M.
                  <span className="client-designation">Sugar product</span>
                </h3>
              </Carousel.Item>
              <Carousel.Item className="single-testimonial-wrap layout--2">
                <div className="row">
                  <p className="col-md-10">
                    <sup className="quote-icon">“</sup>En tant que chef de
                    projet, la gestion des équipes de production / rentabilité
                    est une tâche assez complexe à gérer au quotidien et celà
                    devient plus complexe lorsqu’on cherche à réaliser des
                    rapports pour la direction. Grâce à Workload, je peux
                    générer cela en quelque clic et de façon automatisée.
                    <sup className="quote-icon-right">”</sup>
                  </p>
                </div>
                <h3 className="client-name">
                  Laetitia R.
                  <span className="client-designation">Kickdreamer</span>
                </h3>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </section>

      <section className="portfolio-area-wrapper mt-120 mt-md-80 mt-sm-60">
        <div className="container">
          <div className="row">
            <div className="col-md-12 m-auto">
              <div className="section-title-wrap">
                <h2 data-trans="home.how.title">
                  {t(
                    "home.how.title",
                    "Comment réussir la gestion de plan charge d'un projet ?"
                  )}
                </h2>

                <div
                  className="mb-0"
                  data-trans="home.how.content"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "home.how.content",
                      "<p>" +
                        "                    Afin d'assurer la réussite parfaite d'un projet, vous devez" +
                        "                    piloter efficacement l'ensemble des ressources humaines &" +
                        "                    matérielles dont vous disposez. A cette étape, le plan de" +
                        "                    charge est votre seul et unique outil à adopter afin" +
                        "                    d'anticiper & optimiser l'affectation de vos ressources." +
                        "                  </p>" +
                        "                  <p>" +
                        "                    Il existe autant de modèles disponibles (plan de charge" +
                        "                    excel, logiciel et outil de plan de charge, outil de gestion" +
                        "                    de projet...) mais comment décidez-vous lequel utiliser ?" +
                        "                  </p>" +
                        "                  <p>" +
                        "                    Au préalable, la planification de projet permet d’évaluer" +
                        "                    pour chaque tâche :" +
                        "                  </p>" +
                        "                  <ul>" +
                        "                    <li>- Sa durée totale,</li>" +
                        "                    <li>- Le nombre de ressources nécessaires,</li>" +
                        "                    <li>- Les profils adaptés aux tâches,</li>" +
                        "                    <li>" +
                        "                      - Les contraintes liées aux ressources humaines à prendre" +
                        "                      en compte, comme les congés, les RTT, les modes de" +
                        "                      travail, etc." +
                        "                    </li>" +
                        "                  </ul>"
                    ),
                  }}
                />
                <p>
                  <Link to="/try-it">Essayer dès maintenant</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="brand-logo-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <img src="/assets/img/brand-logo/brand-logo-01.png" alt="PHP" />
            </div>
            <div className="col-md-3">
              <img
                src="/assets/img/brand-logo/brand-logo-02.png"
                alt="Symfony"
              />
            </div>
            <div className="col-md-3">
              <img src="/assets/img/brand-logo/brand-logo-03.png" alt="React" />
            </div>
            <div className="col-md-3">
              <img
                src="/assets/img/brand-logo/brand-logo-04.png"
                alt="Api platform"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
